<style scoped>
/* .product-title{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.2em;
    height: 2.2em;
    margin-left: 6px;
} */
</style>
<template>
    <div  @click="dialog.show = true">
        <slot>
            <el-button type="primary">添加商品</el-button>
        </slot>
    </div>

    <el-dialog v-model="dialog.show" title="选择商品" width="800px" align-center append-to-body>
        <template #header>
            <div style="display:flex;">
                <el-input v-model="query.product_keyword" placeholder="商品ID/关键词" clearable style="max-width:200px;margin-right:10px;" @keyup.enter="search">
                </el-input>
                <el-button type="primary" @click="search">搜索</el-button>
            </div>
            <!-- <el-form inline>     
                <el-form-item> 
                    
                </el-form-item>

                <el-form-item>
                    <el-input v-model="query.product_id" placeholder="输入商品编号" clearable/>
                </el-form-item>
                
                <el-form-item>
                    
                </el-form-item>
            </el-form> -->
        </template>
        
        <el-scrollbar max-height="61.8vh" style="margin-top2:-38px;">
            <el-table 
                v-loading="dialog.loading"
                :data="rows"
                @selection-change="handleSelectionChange" ref="productSelector">
                
                <el-table-column
                type="selection"
                width="42">
                </el-table-column>
                
                <el-table-column label="商品名称" width2="200px">  
                    <template #default="scope">
                        <div style="display:flex;align-items:center;">
                            <!-- <el-avatar shape="square" :size="50" :src="scope.row.head_image_list[0]['preview_image']"></el-avatar> -->
                            <img style="width:50px;height:50px;flex-shrink:0;" :src="productImage(scope.row)" fit="cover"/>
                            <div style="margin-left:8px">
                                <div class="product-title">{{scope.row.product_title}}</div>
                                <div>
                                    编号: {{scope.row.product_id}}
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="价格库存" width="100px">
                    <template #default="scope">
                        ¥{{scope.row.min_price/100}}<br/>
                        {{scope.row.total_stock_count}}
                    </template>
                </el-table-column>

                <el-table-column width="100px" label="状态" prop="product_status_desc">
                </el-table-column>

            </el-table>
        </el-scrollbar>

        <template #footer>
            <div style="display:flex;justify-content:space-between;align-items:center;">
                <div style="width:100px;text-align:left;">
                    已经({{selected_rows.length}})
                </div>

                <el-pagination :pager-count="4" background
                    @current-change="handleCurrentChange"
                    :current-page="query.page"
                    :page-size="query.page_size"
                    layout="total, prev, next, jumper"
                    :total="total">
                </el-pagination>
                
                <div>
                    <el-button @click="dialog.show = false">取消</el-button>
                    <el-button type="primary" @click="save">确定</el-button>
                </div>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import{getApi} from '@/plugin/http'

export default {
    props:{
        modelValue:{
            type: Array,
        },
        show:{
            type: Boolean,
        }
    },
    data: ()=> ({
        dialog:{
            show: false,
            loading: false,
        },
        query:{
            page: 1,
            page_size: 20,
        },
        rows:[],
        total: 0,
        selected_rows: [],
        product_rows: []
    }),
    computed: {
        productImage(){
            return item => {
                return item && item.head_image_list && item.head_image_list[0] ? item.head_image_list[0]['preview_image'] : ''
            }
        },
    },
    watch:{
        modelValue: function(){ // product_id_arr
            // TODO 算出选中的商品
            // getApi('/admin/shop/product/product_list', {
            //     product_id: this.modelValue,
            //     page_size: 200,
            //     page: 1,
            // }).then(re => {
            //     let data = re.data
            //     this.product_rows = data.rows
            // }).catch(this.$message.error)
        },
        'dialog.show': function(){
            this.fetchRows();
        }
    },
    created(){
        
    },
    methods:{
        showDialog(){
            this.dialog.show = true
            // this.fetchRows();
        },
        delProduct(idx){
            this.product_rows.splice(idx,1);
            this.$emit('update:modelValue', this.product_rows.map(v => v.product_id))
        },
        save(){
            let product_id_arr = this.product_rows.map(v => v.product_id);

            this.selected_rows.forEach(v => {
                if(product_id_arr.indexOf(v.product_id) == -1) {
                    this.product_rows.push(v);
                }
            })

            // console.log('product_id_arr', product_id_arr)
            this.$emit('update:modelValue', this.product_rows.map(v => v.product_id))
            this.$emit('save', {
                product_id_arr: this.product_rows.map(v => v.product_id),
                product_list: this.product_rows,
            })

            this.selected_rows = []
            this.product_rows = []
            this.dialog.show = false
        },
        handleSelectionChange(rows){
            this.selected_rows = rows;
        },
        handleCurrentChange(page){
            this.fetchRows({
                page: page,
            });
        },
        search(){
            this.fetchRows();
        },
        fetchRows(opt){
            console.log('this.$refs', this.$refs)

            getApi('/admin/shop/product/product_list', Object.assign(this.query, opt || {}))
            .then(re => {
                let data = re.data
                this.rows = data.rows
                this.total = data.total

                // TODO more think
                setTimeout(() => {
                    data.rows.map(row => {
                        if(this.modelValue) {
                            console.log('')
                            if(this.modelValue.indexOf(row.product_id) != -1) {
                                this.$refs.productSelector.toggleRowSelection(row, true)
                                this.selected_rows.push(row);
                            }
                        }
                    })
                }, 500);

            }).catch(this.$handleError)
        }
    }
}
</script>
