import * as VueRouter from 'vue-router'
// import { createRouter } from 'vue-router'
// const VueRouter = window.VueRouter
let createRoute = (path, comp, title) => {
	return {
		path: path,
		component: () => import('@/view/' + comp),
		meta: {
			title: title,
		}
	}
}

// 根据不同的子域名加在对应项目的routes
// TODO privilege check
const routes = [
	{
		path: '/kds',
		component: () => import('@/view/shop/kds/KdsLayout'),
		meta: {
			title: 'kds',
		},
		children:[
			{
				path: 'home',
				component: () => import('@/view/shop/kds/KdsHome'),
				meta: {
					title: 'kds',
				},
			}
		]
	},
	// 商城
	{
		path: '/',
		redirect: '/home',
		component:  /layout=pos/.test(location.search) ? () => import('@/view/shop/PosLayout') : () => import('@/view/shop/ShopLayout'),
		meta: {
			title: '小店管理后台',
		},
		children: [
			{
				path: 'home',
				name: 'ShopHome',
				component: () => import('@/view/shop/Home'),
				meta: {
					title: '首页',
				},
			},

			{
				path: 'product/search/keyword',
				name: 'ProductSearchKeyword',
				component: () => import('@/view/shop/product/ProductSearchKeyword'),
				meta: {
					title: '搜索词',
				},
			},
			{
				path: 'post/list',
				name: 'PostList',
				component: () => import('@/view/shop/post/PostList'),
				meta: {
					title: '帖子列表',
				},
			},
			{
				path: 'post/comment',
				name: 'PostComment',
				component: () => import('@/view/shop/post/PostComment'),
				meta: {
					title: '评论列表',
				},
			},
			{
				path: 'post/topic',
				name: 'PostTopic',
				component: () => import('@/view/shop/post/PostTopic'),
				meta: {
					title: '话题列表',
				},
			},

			{
				path: 'merchant/list',
				name: 'MerchantList',
				component: () => import('@/view/shop/merchant/MerchantList'),
				meta: {
					title: '商户列表',
				},
			},
			{
				path: 'merchant/setting',
				name: 'MerchantSetting',
				component: () => import('@/view/shop/merchant/MerchantSetting'),
				meta: {
					title: '商户设置',
				},
			},


			{
				path: 'service/staff',
				name: 'ServiceStaff',
				component: () => import('@/view/shop/service/ServiceStaff'),
				meta: {
					title: '人员管理',
				},
			},
			{
				path: 'service/template',
				name: 'ServiceTemplate',
				component: () => import('@/view/shop/service/ServiceTemplate'),
				meta: {
					title: '模版管理',
				},
			},
			{
				path: 'service/list',
				name: 'ServiceList',
				component: () => import('@/view/shop/service/ServiceList'),
				meta: {
					title: '服务模版',
				},
			},
			{
				path: 'service/order',
				name: 'ServiceOrder',
				component: () => import('@/view/shop/service/ServiceOrder'),
				meta: {
					title: '订单列表',
				},
			},

			{
				path: 'delivery/samecity/provider',
				name: 'DeliverySamecityProvider',
				component: () => import('@/view/shop/delivery/DeliverySamecityProvider'),
				meta: {
					title: '服务商',
				},
			},
			{
				path: 'delivery/samecity/provider/shop',
				name: 'DeliverySamecityProviderShop',
				component: () => import('@/view/shop/delivery/DeliverySamecityProviderShop'),
				meta: {
					title: '发货门店',
				},
			},
			{
				path: 'delivery/samecity/order',
				name: 'DeliverySamecityOrder',
				component: () => import('@/view/shop/delivery/DeliverySamecityOrder'),
				meta: {
					title: '同城配送',
				},
			},
			{
				path: 'delivery/recycle/order',
				name: 'DeliveryRecycleOrder',
				component: () => import('@/view/shop/delivery/DeliveryRecycleOrder'),
				meta: {
					title: '回收订单',
				},
			},
			{
				path: 'delivery/samecity/staff',
				name: 'DeliverySamecityStaff',
				component: () => import('@/view/shop/delivery/DeliverySamecityStaff'),
				meta: {
					title: '骑手管理',
				},
			},
			{
				path: 'desk/list',
				name: 'DeskList',
				component: () => import('@/view/shop/desk/DeskList'),
				meta: {
					title: '桌台管理',
				},
			},
			{
				path: 'reserve/rule',
				name: 'ReserveRule',
				component: () => import('@/view/shop/reserve/ReserveRule'),
				meta: {
					title: '预约规则',
				},
			},

			// {
			// 	path: 'delivery/order',
			// 	name: 'DeliveryOrder',
			// 	component: () => import('@/view/shop/delivery/order'),
			// 	meta: {
			// 		title: '订单管理',
			// 	},
			// },


			{
				path: 'pos/console',
				name: 'PosConsole',
				component: () => import('@/view/shop/pos/PosConsole'),
				meta: {
					title: '收银台',
				},
			},
			{
				path: 'pos/work',
				name: 'PosWork',
				component: () => import('@/view/shop/pos/PosWork'),
				meta: {
					title: '交班',
				},
			},

			{
				path: 'exchange/list',
				name: 'ExchangeList',
				component: () => import('@/view/shop/exchange/ExchangeList'),
				meta: {
					title: '兑换',
				},
			},
			{
				path: 'exchange/edit',
				name: 'ExchangeEdit',
				component: () => import('@/view/shop/exchange/ExchangeEdit'),
				meta: {
					title: '兑换信息',
				},
			},
			{
				path: 'exchange/card',
				name: 'ExchangeCard',
				component: () => import('@/view/shop/exchange/ExchangeCard'),
				meta: {
					title: '兑换卡',
				},
			},

			{
				path: 'download/export',
				name: 'DownloadExport',
				component: () => import('@/view/shop/download/DownloadExport'),
				meta: {
					title: '数据导出',
				},
			},

			{
				path: 'user/list',
				name: 'UserList',
				component: () => import('@/view/shop/user/UserList'),
				meta: {
					title: '用户列表',
				},
			},
			{
				path: 'user/detail',
				name: 'UserDetail',
				component: () => import('@/view/shop/user/UserDetail'),
				meta: {
					title: '用户详情',
				},
			},
			{
				path: 'user/group',
				name: 'UserGroup',
				component: () => import('@/view/shop/user/UserGroup'),
				meta: {
					title: '用户分组',
				},
			},
			{
				path: 'user/tag',
				name: 'UserTag',
				component: () => import('@/view/shop/user/UserTag'),
				meta: {
					title: '用户标签',
				},
			},
			{
				path: 'user/import',
				name: 'UserImport',
				component: () => import('@/view/shop/user/UserImport'),
				meta: {
					title: '用户导入',
				},
			},
			{
				path: 'user/import/row',
				name: 'UserImportRow',
				component: () => import('@/view/shop/user/UserImportRow'),
				meta: {
					title: '用户导入记录',
				},
			},

			{
				path: 'vip/list',
				name: 'VipList',
				component: () => import('@/view/shop/vip/VipList'),
				meta: {
					title: '等级权益',
				},
			},
			{
				path: 'vip/card',
				name: 'VipCard',
				component: () => import('@/view/shop/vip/VipCard'),
				meta: {
					title: '会员卡',
				},
			},
			{
				path: 'vip/card/edit',
				name: 'VipCardEdit',
				component: () => import('@/view/shop/vip/VipCardEdit'),
				meta: {
					title: '会员卡设置',
				},
			},
			{
				path: 'vip/card/benefit',
				name: 'VipCardBenefit',
				component: () => import('@/view/shop/vip/VipCardBenefit'),
				meta: {
					title: '会员权益',
				},
			},
			{
				path: 'vip/card/benefit/product',
				name: 'VipCardBenefitProduct',
				component: () => import('@/view/shop/vip/VipCardBenefitProduct'),
				meta: {
					title: '商品折扣',
				},
			},
			{
				path: 'vip/card/level',
				name: 'VipLevel',
				component: () => import('@/view/shop/vip/VipLevel'),
				meta: {
					title: '会员等级',
				},
			},
			{
				path: 'vip/card/level/edit',
				name: 'VipLevelEdit',
				component: () => import('@/view/shop/vip/VipLevelEdit'),
				meta: {
					title: '等级设置',
				},
			},
			{
				path: 'vip/card/level/benefit',
				name: 'VipLevelBenefit',
				component: () => import('@/view/shop/vip/VipLevelBenefit'),
				meta: {
					title: '等级权益',
				},
			},
			// {
			// 	path: 'vip/level/reward',
			// 	name: 'VipLevelReward',
			// 	component: () => import('@/view/shop/vip/VipLevelReward'),
			// 	meta: {
			// 		title: '等级权益',
			// 	},
			// },

			{
				path: 'union/order',
				name: 'UnionOrder',
				component: () => import('@/view/shop/union/UnionOrder'),
				meta: {
					title: '分销订单',
				},
			},
			{
				path: 'union/user',
				name: 'UnionUser',
				component: () => import('@/view/shop/union/UnionUser'),
				meta: {
					title: '分销客户',
				},
			},
			{
				path: 'union/withdraw',
				name: 'UnionWithdraw',
				component: () => import('@/view/shop/union/UnionWithdraw'),
				meta: {
					title: '提现管理',
				},
			},
			{
				path: 'union/setting',
				name: 'UnionSetting',
				component: () => import('@/view/shop/union/UnionSetting'),
				meta: {
					title: '分销设置',
				},
			},
			{
				path: 'union/product',
				name: 'UnionProduct',
				component: () => import('@/view/shop/union/UnionProduct'),
				meta: {
					title: '商品设置',
				},
			},
			{
				path: 'union/promoter',
				name: 'UnionPromoter',
				component: () => import('@/view/shop/union/UnionPromoter'),
				meta: {
					title: '分销员',
				},
			},

			{
				path: 'alliance/promoter',
				name: 'AlliancePromoter',
				component: () => import('@/view/shop/alliance/AlliancePromoter'),
				meta: {
					title: '分销商',
				},
			},
			{
				path: 'alliance/product',
				name: 'AllianceProduct',
				component: () => import('@/view/shop/alliance/AllianceProduct'),
				meta: {
					title: '商品设置',
				},
			},
			{
				path: 'alliance/level',
				name: 'AllianceLevel',
				component: () => import('@/view/shop/alliance/AllianceLevel'),
				meta: {
					title: '等级管理',
				},
			},

			// {
			// 	path: 'vip/level/benefit/edit',
			// 	name: 'VipLevelBenefitEdit',
			// 	component: () => import('@/view/shop/vip/VipLevelBenefitEdit'),
			// 	meta: {
			// 		title: '等级权益设置',
			// 	},
			// },

			{
				path: 'credit/config',
				name: 'CreditConfig',
				component: () => import('@/view/shop/credit/CreditConfig'),
				meta: {
					title: '积分配置',
				},
			},
			{
				path: 'credit/product',
				name: 'CreditProduct',
				component: () => import('@/view/shop/credit/CreditProduct'),
				meta: {
					title: '商品管理',
				},
			},
			{
				path: 'credit/log',
				name: 'CreditLog',
				component: () => import('@/view/shop/credit/CreditLog'),
				meta: {
					title: '积分日志',
				},
			},

			{
				path: 'wallet/config',
				name: 'WalletConfig',
				component: () => import('@/view/shop/wallet/WalletConfig'),
				meta: {
					title: '储值配置',
				},
			},
			{
				path: 'wallet/card/:card_type(.*)',
				name: 'WalletCard',
				component: () => import('@/view/shop/wallet/WalletCard'),
				meta: {
					title: '储值卡',
				},
			},
			{
				path: 'wallet/card/:card_type(.*)/edit',
				name: 'WalletCardEdit',
				component: () => import('@/view/shop/wallet/WalletCardEdit'),
				meta: {
					title: '储值卡',
				},
			},
			{
				path: 'wallet/card/:card_type(.*)/exchange',
				name: 'WalletCardExchange',
				component: () => import('@/view/shop/wallet/WalletCardExchange'),
				meta: {
					title: '储值卡',
				},
			},
			{
				path: 'wallet/bill',
				name: 'WalletBill',
				component: () => import('@/view/shop/wallet/WalletBill'),
				meta: {
					title: '账单明细',
				},
			},

			{
				path: 'help/contact',
				name: 'HelpContact',
				component: () => import('@/view/shop/help/HelpContact'),
				meta: {
					title: '联系我们',
				},
			},
			{
				path: 'channel/miniprogram',
				name: 'ChannelMiniprogram',
				component: () => import('@/view/shop/channel/ChannelMiniprogram'),
				meta: {
					title: '管理设置',
				},
			},
			{
				path: 'channel/miniprogram/upgrade',
				name: 'ChannelMiniprogramUpgrade',
				component: () => import('@/view/shop/channel/ChannelMiniprogramUpgrade'),
				meta: {
					title: '小程序版本更新',
				},
			},
			{
				path: 'channel/miniprogram/reg',
				name: 'ChannelMiniprogramReg',
				component: () => import('@/view/shop/channel/ChannelMiniprogramReg'),
				meta: {
					title: '注册小程序',
				},
			},
			{
				path: 'channel/miniprogram/icp_reg',
				name: 'ChannelMiniprogramIcpReg',
				component: () => import('@/view/shop/channel/ChannelMiniprogramIcpReg'),
				meta: {
					title: '小程序备案',
				},
			},
			{
				path: 'channel/miniprogram/verify/apply',
				name: 'ChannelMiniprogramVerifyApply',
				component: () => import('@/view/shop/channel/ChannelMiniprogramVerifyApply'),
				meta: {
					title: '小程序备案',
				},
			},
			{
				path: 'weixin/store',
				name: 'WeixinChannelShop',
				component: () => import('@/view/shop/weixinChannel/WeixinChannelShop'),
				meta: {
					title: '微信小店',
				},
			},


			{
				path: 'code/verify',
				name: 'CodeVerify',
				component: () => import('@/view/shop/code/CodeVerify'),
				meta: {
					title: '券码核销',
				},
			},
			{
				path: 'code/verify/log',
				name: 'CodeVerifyLog',
				component: () => import('@/view/shop/code/CodeVerifyLog'),
				meta: {
					title: '核销记录',
				},
			},
			// {
			// 	path: 'app/weixin',
			// 	name: 'AppWeixin',
			// 	component: () => import('@/view/shop/app/AppWeixin'),
			// 	meta: {
			// 		title: '微信小程序',
			// 	},
			// },

			{
				path: 'finance/payment',
				name: 'FinancePayment',
				component: () => import('@/view/shop/finance/FinancePayment'),
				meta: {
					title: '支付管理',
				},
			},
			{
				path: 'finance/payment/edit',
				name: 'FinancePaymentEdit',
				component: () => import('@/view/shop/finance/FinancePaymentEdit'),
				meta: {
					title: '编辑支付信息',
				},
			},
			{
				path: 'finance/transaction',
				name: 'FinanceTransaction',
				component: () => import('@/view/shop/finance/FinanceTransaction'),
				meta: {
					title: '收支明细',
				},
			},
			{
				path: 'invoice/receipt/list',
				name: 'InvoiceReceiptList',
				component: () => import('@/view/shop/invoice/InvoiceReceiptList'),
				meta: {
					title: '开票记录',
				},
			},
			{
				path: 'invoice/config',
				name: 'InvoiceConfig',
				component: () => import('@/view/shop/invoice/InvoiceConfig'),
				meta: {
					title: '发票设置',
				},
			},

			{
				path: 'pay/code',
				name: 'PayCode',
				component: () => import('@/view/shop/pay/PayCode'),
				meta: {
					title: '收款码',
				},
			},
			{
				path: 'qrcode/list',
				name: 'QrcodeList',
				component: () => import('@/view/shop/qrcode/QrcodeList'),
				meta: {
					title: '二维码',
				},
			},

			// {
			// 	path: 'finance/settle',
			// 	name: 'FinanceSettle',
			// 	component: () => import('@/view/shop/finance/FinanceSettle'),
			// 	meta: {
			// 		title: '资金结算',
			// 	},
			// },
			// {
			// 	path: 'finance/settle/account',
			// 	name: 'FinanceSettleAccount',
			// 	component: () => import('@/view/shop/finance/FinanceSettleAccount'),
			// 	meta: {
			// 		title: '结算账户',
			// 	},
			// },
			{
				path: 'statistics/overview',
				name: 'StatisticsOverview',
				component: () => import('@/view/shop/statistics/StatisticsOverview'),
				meta: {
					title: '经营概括',
				},
			},
			{
				path: 'statistics/product',
				name: 'StatisticsProduct',
				component: () => import('@/view/shop/statistics/StatisticsProduct'),
				meta: {
					title: '商品数据',
				},
			},
			{
				path: 'statistics/shop',
				name: 'StatisticsShop',
				component: () => import('@/view/shop/statistics/StatisticsShop'),
				meta: {
					title: '门店数据',
				},
			},

			{
				path: 'order/list',
				name: 'OrderList',
				component: () => import('@/view/shop/order/OrderList'),
				meta: {
					title: '订单列表',
				},
			},
			{
				path: 'order/detail',
				name: 'OrderDetail',
				component: () => import('@/view/shop/order/OrderDetail'),
				meta: {
					title: '订单详情',
				},
			},
			{
				path: 'order/post',
				name: 'OrderPost',
				component: () => import('@/view/shop/order/OrderPost'),
				meta: {
					title: '订单评价',
				},
			},
			{
				path: 'order/create',
				name: 'OrderCreate',
				component: () => import('@/view/shop/order/OrderCreate'),
				meta: {
					title: '新增订单',
				},
			},

			{
				path: 'sms/template',
				name: 'SmsTemplate',
				component: () => import('@/view/shop/sms/SmsTemplate'),
				meta: {
					title: '短信模版',
				},
			},
			{
				path: 'sms/task',
				name: 'SmsTask',
				component: () => import('@/view/shop/sms/SmsTask'),
				meta: {
					title: '短信群发',
				},
			},
			{
				path: 'sms/log',
				name: 'SmsLog',
				component: () => import('@/view/shop/sms/SmsLog'),
				meta: {
					title: '发送日志',
				},
			},
			{
				path: 'sms/recharge',
				name: 'SmsRecharge',
				component: () => import('@/view/shop/sms/SmsRecharge'),
				meta: {
					title: '短信充值',
				},
			},

			{
				path: 'order/post/comment',
				name: 'OrderPostComment',
				component: () => import('@/view/shop/order/OrderPostComment'),
				meta: {
					title: '评论列表',
				},
			},

            {
                path: 'aftersale/list',
                name: 'AftersaleList',
                component: () => import('@/view/shop/aftersale/AftersaleList'),
				meta: {
					title: '售后列表',
				},
            },
            {
                path: 'aftersale/detail',
                name: 'AftersaleDetail',
                component: () => import('@/view/shop/aftersale/AftersaleDetail'),
				meta: {
					title: '售后详情',
				},
            },

            {
                path: 'insurance/delivery',
                name: 'InsuranceDelivery',
                component: () => import('@/view/shop/insurance/InsuranceDelivery'),
				meta: {
					title: '运费险',
				},
            },

			{
				path: 'order/delivery/setting',
				name: 'OrderDelivery',
				component: () => import('@/view/shop/order/OrderDelivery'),
				meta: {
					title: '配送设置',
				},
			},
			{
				path: 'order/delivery/tpl',
				name: 'OrderDeliveryTplEdit',
				component: () => import('@/view/shop/order/OrderDeliveryTplEdit'),
				meta: {
					title: '配送模版',
				},
			},

			{
				path: 'product/cate',
				name: 'ProductCate',
				component: () => import('@/view/shop/product/ProductCate'),
				meta: {
					title: '商品分类',
				},
			},
			{
				path: 'product/cate/product',
				name: 'ProductCateProduct',
				component: () => import('@/view/shop/product/ProductCateProduct'),
				meta: {
					title: '商品分类',
				},
			},
			{
				path: 'product/tag',
				name: 'ProductTag',
				component: () => import('@/view/shop/product/ProductTag'),
				meta: {
					title: '商品标签',
				},
			},
			{
				path: 'product/tag/product',
				name: 'ProductTagProduct',
				component: () => import('@/view/shop/product/ProductTagProduct'),
				meta: {
					title: '商品标签',
				},
			},
			{
				path: 'product/edit',
				name: 'ProductEdit',
				component: () => import('@/view/shop/product/ProductEdit'),
				meta: {
					title: '编辑商品',
				},
			},
			{
				path: 'product/list',
				name: 'ProductList',
				component: () => import('@/view/shop/product/ProductList'),
				meta: {
					title: '商品列表',
				},
			},
			{
				path: 'product/browse',
				name: 'ProductBrowse',
				component: () => import('@/view/shop/product/ProductBrowse'),
				meta: {
					title: '浏览记录',
				},
			},

			{
				path: 'product/code',
				name: 'ProductCode',
				component: () => import('@/view/shop/product/ProductCode'),
				meta: {
					title: '商品卡密',
				},
			},
			{
				path: 'product/group',
				name: 'ProductGroup',
				component: () => import('@/view/shop/product/ProductGroup'),
				meta: {
					title: '商品分组',
				},
			},
			{
				path: 'product/group/product',
				name: 'ProductGroupProduct',
				component: () => import('@/view/shop/product/ProductGroupProduct'),
				meta: {
					title: '商品分组管理',
				},
			},
			{
				path: 'product/template',
				name: 'ProductTemplate',
				component: () => import('@/view/shop/product/ProductTemplate'),
				meta: {
					title: '商品模版',
				},
			},

			// {
			// 	path: 'operation/carousel',
			// 	name: 'OperationCarousel',
			// 	component: () => import('@/view/shop/operation/OperationCarousel'),
			// 	meta: {
			// 		title: '轮播广告',
			// 	},
			// },
			{
				path: 'notice/list',
				name: 'NoticeList',
				component: () => import('@/view/shop/notice/NoticeList'),
				meta: {
					title: '公告列表',
				},
			},
			{
				path: 'notice/edit',
				name: 'NoticeEdit',
				component: () => import('@/view/shop/notice/NoticeEdit'),
				meta: {
					title: '公告编辑',
				},
			},
			{
				path: 'popup/list',
				name: 'PopupList',
				component: () => import('@/view/shop/popup/PopupList'),
				meta: {
					title: '弹窗公告',
				},
			},
			{
				path: 'popup/edit',
				name: 'PopupEdit',
				component: () => import('@/view/shop/popup/PopupEdit'),
				meta: {
					title: '弹窗编辑',
				},
			},
			{
				path: 'app/share',
				name: 'AppShare',
				component: () => import('@/view/shop/app/AppShare'),
				meta: {
					title: '页面分享',
				},
			},

			{
				path: 'marketing/coupon',
				name: 'CouponList',
				component: () => import('@/view/shop/marketing/coupon/CouponList'),
				meta: {
					title: '优惠券列表',
				},
			},
			// {
			// 	path: 'marketing/coupon/edit',
			// 	name: 'CouponEdit',
			// 	component: () => import('@/view/shop/marketing/coupon/CouponEdit'),
			// 	meta: {
			// 		title: '优惠券',
			// 	},
			// },
			{
				path: 'marketing/coupon/receive_log',
				name: 'CouponReceiveLog',
				component: () => import('@/view/shop/marketing/coupon/CouponReceiveLog'),
				meta: {
					title: '领取日志',
				},
			},

			{
				path: 'marketing/activity/:activity_type(.*)',
				name: 'MarketingActivity',
				component: () => import('@/view/shop/marketing/MarketingActivity'),
				meta: {
					title: '活动列表',
				},
			},
			{
				path: 'marketing/activity/:activity_type(.*)/edit',
				name: 'MarketingActivityEdit',
				component: () => import('@/view/shop/marketing/MarketingActivityEdit'),
				meta: {
					title: '活动信息',
				},
			},
			{
				path: 'marketing/activity/:activity_type(.*)/product',
				name: 'MarketingActivityProduct',
				component: () => import('@/view/shop/marketing/MarketingActivityProduct'),
				meta: {
					title: '商品管理',
				},
			},
			{
				path: 'marketing/activity/:activity_type(.*)/log',
				name: 'MarketingActivityLog',
				component: () => import('@/view/shop/marketing/MarketingActivityLog'),
				meta: {
					title: '活动日志',
				},
			},

			{
				path: 'shop/list', // :shop_type(offline|online)
				name: 'ShopList',
				component: () => import('@/view/shop/shop/ShopList'),
				meta: {
					title: '店铺列表',
				},
			},
			{
				path: 'shop/info',
				name: 'ShopInfo',
				component: () => import('@/view/shop/shop/ShopEdit'),
				meta: {
					title: '店铺编辑',
				},
			},
			{
				path: 'shop/edit',
				name: 'ShopEdit',
				component: () => import('@/view/shop/shop/ShopEdit'),
				meta: {
					title: '店铺编辑',
				},
			},

			{
				path: 'address/:address_type',
				name: 'AddressList',
				component: () => import('@/view/shop/address/AddressList'),
				meta: {
					title: '地址库',
				},
			},
			{
				path: 'address/:address_type/edit',
				name: 'AddressEdit',
				component: () => import('@/view/shop/address/AddressEdit'),
				meta: {
					title: '地址设置',
				},
			},

			{
				path: 'decoration/page',
				name: 'DecorationPage',
				component: () => import('@/view/shop/decoration/DecorationPage'),
				meta: {
					title: '页面管理',
				},
			},
			{
				path: 'decoration/template',
				name: 'DecorationTemplate',
				component: () => import('@/view/shop/decoration/DecorationTemplate'),
				meta: {
					title: '装修模版',
				},
			},
			{
				path: 'decoration/setting',
				name: 'DecorationSetting',
				component: () => import('@/view/shop/decoration/DecorationSetting'),
				meta: {
					title: '装修设置',
				},
			},

			{
				path: 'app/info',
				name: 'AppInfo',
				component: () => import('@/view/main/app/AppInfo'),
				meta: {
					title: '应用信息',
				},
			},


			{
				path: 'staff/list',
				name: 'StaffList',
				component: () => import('@/view/main/staff/StaffList'),
				meta: {
					title: '账号列表',
				},
			},
			{
				path: 'staff/edit',
				name: 'StaffEdit',
				component: () => import('@/view/main/staff/StaffEdit'),
				meta: {
					title: '编辑账号',
				},
			},
			{
				path: 'file/list',
				name: 'FileList',
				component: () => import('@/view/main/file/FileList'),
				meta: {
					title: '文件列表',
				},
			},
			{
				path: ':type/group',
				name: 'GroupList',
				component: () => import('@/view/main/group/GroupList'),
				meta: {
					title: '分组管理',
				},
			},
			// {
			// 	path: 'staff/info',
			// 	name: 'StaffInfo',
			// 	component: () => import('@/view/main/staff/StaffInfo'),
			// 	meta: {
			// 		title: '账号信息',
			// 	},
			// },
			{
				path: 'role/list',
				name: 'RoleList',
				component: () => import('@/view/main/role/RoleList'),
				meta: {
					title: '角色列表',
				},
			},
			{
				path: 'role/edit',
				name: 'RoleEdit',
				component: () => import('@/view/main/role/RoleEdit'),
				meta: {
					title: '角色列表',
				},
			},
			{
				path: 'erp/list',
				name: 'ERPList',
				component: () => import('@/view/shop/integrated/ERPList'),
				meta: {
					title: 'ERP',
				},
			},
			{
				path: 'erp/edit',
				name: 'ERPEdit',
				component: () => import('@/view/shop/integrated/ERPEdit'),
				meta: {
					title: 'ERP',
				},
			},

			{
				path: 'form/list',
				name: 'FormList',
				component: () => import('@/view/shop/form/FormList'),
				meta: {
					title: '表单列表',
				},
			},
			{
				path: 'form/edit',
				name: 'FormEdit',
				component: () => import('@/view/shop/form/FormEdit'),
				meta: {
					title: '表单设置',
				},
			},
			{
				path: 'form/fill',
				name: 'FormFill',
				component: () => import('@/view/shop/form/FormFill'),
				meta: {
					title: '表单数据',
				},
			},

			{
				path: 'app/setting',
				name: 'AppSetting',
				component: () => import('@/view/shop/system/AppSetting'),
				meta: {
					title: '应用设置',
				},
			},
			{
				path: 'contract/list',
				name: 'ContractList',
				component: () => import('@/view/shop/contract/ContractList'),
				meta: {
					title: '协议管理',
				},
			},
		]
	},
	{
		path: '/decoration/page/design', // shop
		name: 'DecorationPageDesign',
		component: () => import('@/view/shop/decoration/DecorationPageDesign'),
		meta: {
			title: '页面设计',
		},
	},
	{
		path: '/form/design',
		name: 'FormDesign',
		component: () => import('@/view/shop/form/FormDesign'),
		meta: {
			title: '表单设计',
		},
	},
	{
		path: '/alliance/market',
		name: 'AllianceMarket',
		component: () => import('@/view/shop/alliance/AllianceMarket'),
		meta: {
			title: '选品中心',
		},
	},

	// createRoute('/home', 'Home', '欢迎回来'),
	createRoute('/rule/user-rule', 'rule/UserRule', '用户协议'),
	createRoute('/rule/privacy-rule', 'rule/PrivacyRule', '隐私协议'),
	// 商城结束
	createRoute('/:action_type(login|register|reset_password)', 'main/staff/StaffLogin', (to) => {
		let map = {
			login: '登录',
			register: '注册',
			reset_password: '重置密码',
		}

		let action_type = to.params.action_type

		if (map[action_type]) {
			return map[action_type]
		}
	}),

	{
		path: '/app/oauth',
		name: 'AppOAuth',
		component: () => import('@/view/main/app/AppOAuth'),
		meta: {
			title: '授权',
		},
	},

	// 收银台
	// {
	// 	path: '/pos',
	// 	component: () => import('@/view/shop/PosLayout'),
	// 	children:[
	// 		{
	// 			path: 'console',
	// 			name: 'PosConsole',
	// 			component: () => import('@/view/shop/pos/PosConsole'),
	// 			meta: {
	// 				title: '收银台',
	// 			},
	// 		},
	// 		{
	// 			path: 'work',
	// 			name: 'PosWork',
	// 			component: () => import('@/view/shop/pos/PosWork'),
	// 			meta: {
	// 				title: '交班',
	// 			},
	// 		},		
	// 	]
	// },

	// 主
	{
		path: '/',
		// redirect: '/home',
		component: () => import('@/view/main/Layout'),
		meta: {
			title: '管理后台',
		},
		children: [
			{
				path: 'app',
				name: 'app',
				component: () => import('@/view/main/Home'),
				meta: {
					title: '首页',
				},
			},
			{
				path: 'account',
				name: 'Account',
				component: () => import('@/view/main/Account'),
				meta: {
					title: '账号信息',
				},
			},
		]
	},
	{
		path: '/contact',
		component: () => import('@/view/Contact'),
	},
	{
		path: '/index',
		component: () => import('@/view/shop/Index'),
	},
	{
		path: '/renew',
		component: () => import('@/view/shop/Renew'),
	},
]

const router = VueRouter.createRouter({
	history: VueRouter.createWebHistory(),
	routes,
})

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = typeof to.meta.title == 'function' ? to.meta.title(to) : to.meta.title
	}

	if (to.query.access_token) {
		localStorage.setItem('app_id', to.query.app_id)
		localStorage.setItem('access_token', to.query.access_token)
		delete (to.query.access_token);
		next(to);
	} else {
		next();
	}
})

export default router